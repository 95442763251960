import React, { Component } from 'react';
import './App.scss';

class ComboBoxSimple extends Component {
  handleOptionChange = (event) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(event.target.value);
    }
  };

  render() {
    const { options, selectedOption, number } = this.props;

    return (
      <div className="form_enter_foodlist">
        <select className="form_enter_foodlist" value={selectedOption} id={number} onChange={this.handleOptionChange}>
          <option value="" disabled hidden>
            Vyber
          </option>
          {options?.length > 0 ? options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          )) : null}
        </select>
      </div>
    );
  }
}

export default ComboBoxSimple;

