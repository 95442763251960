import React from 'react'
import './App.scss'
import './home.scss'
import {useNavigate} from 'react-router-dom'
import { Container} from 'react-bootstrap'

import HeaderPart from './HeaderPart'
import HeaderMenu from './HeaderMenu'

function PlayGround() {
    const navigate = useNavigate();
    const isLoggedIn = document.cookie.includes("loggedInWedding=true");


if(isLoggedIn){
return (
    <div className='App'>
      <HeaderPart/>
      <Container>
      <HeaderMenu/>
     </Container>
    </div>
  )
}
else return (navigate('/login'))
}

export default PlayGround