import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Axios from 'axios';
import './App.scss'
import queryString from 'query-string'
import './popUp.scss'
import {useNavigate} from 'react-router-dom'

function SaveUser() {
  const [newUser, setNewUser] = useState({ username: '', password: '', weddingId: '' });
  const initialUser = { username: '', password: '', weddingId: '' };
  const [succesRegister, setSuccesRegister] = useState(false)
  const [notSame, setNotSame] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [userExist, setUserExist] = useState(true)
  const [loading, setloading] = useState(false)
  const [configSave, setConfigSave] = useState(false)
  const navigate = useNavigate();

  const apiUri = process.env.REACT_APP_APIURL+'register';

  const queryParams = queryString.parse(window.location.search)

  const base64Credentials = btoa(process.env.REACT_APP_APIUSERNAME+':'+process.env.REACT_APP_APIPASSWORD);
  const authHeader = 'Basic ' + base64Credentials;

  const userConfig = {
    headers: {
      Authorization: authHeader
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setConfigSave(false);
    }, 5000);
  
    return () => clearTimeout(timer);
  }, [configSave]);

  const handleSave = (event) => {
    event.preventDefault();
    setNotSame(false)

    const password = event.target.form[1].value; // Získat hodnotu z druhého inputu
    const passwordAgain = event.target.form[2].value;


      if(password === passwordAgain){

            setNewUser({admin: false, username: queryParams.user, password: password, weddingId: queryParams.id});

      }
      else{

          setNotSame(true)
          setErrorMessage('Zadaná hesla nejsou shodná!')
      }

    }

  const saveToDb = async () => {
    setloading(true)
    try {
      console.log("NewUser", newUser)
      const response = await Axios.post(apiUri, newUser, userConfig);
      response.status === 201 ? setSuccesRegister(true) : setSuccesRegister(false)
      console.log("prošlo")
      setloading(false)
    } catch (error) {
      console.log(error);

      if(error.response.status === 404)
      {
        setUserExist(false)
        setErrorMessage('Uživatel není registrován. Obraťte se na správce.')
      }

      setloading(false)
      //throw error; // Re-throw the error to be caught in handleSave if needed
    }
  };

  useEffect(() => {
    // Kontrola, zda newUser byl změněn od své původní hodnoty
    if (newUser.username !== initialUser.username || newUser.password !== initialUser.password || newUser.weddingId !== initialUser.weddingId) {

          const run = async () => {

          try{
            saveToDb();
          }catch (error) {
            console.log(error);
            //throw error; // Re-throw the error to be caught in handleSave if needed
          }
        }
        run()
    }
 
  }, [newUser]);

  useEffect(() => {
    // Axios Interceptor pro zachycení chyb v síťových požadavcích
    const interceptor = Axios.interceptors.response.use(
      response => response,
      error => {
        // Zpracování chyby
        if (error.response && error.response.status === 404) {
          setUserExist(false)
          setErrorMessage('Uživatel není registrován. Obraťte se na správce.')
        }
        return Promise.reject(error);
      }
    );

    // Zrušení interceptoru při odmontování komponenty
    return () => {
      Axios.interceptors.response.eject(interceptor);
    };
  }, []); // Zde je prázdné pole, aby se useEffect spustil pouze při prvním vykreslení

  return (
    <div className='App'>
      <Container>
        <Row>
          <Col>
            <div className="col">
              <div className="row">
                <div className="col-12">
                  <div className="sg-panel sg-panel--paper">
                    <Form>
                      <Form.Group>
                      <Form.Label>
                        <p>
                          {succesRegister ? (
                            <span>
                              <div className='popUp'>Registrace úspěšná! Přejděte na <a href={`/login?id=${queryParams.id}&user=${queryParams.user}`} className="link-light link-underline link-underline-opacity-0">login</a></div>
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                        <b>Uživatel</b>
                      </Form.Label>
                        <br />
                        <Form.Control type="text" className="form_enter" name="user" value={queryParams.user} readOnly />
                        <br />
                        <Form.Label>
                          <b>Heslo</b>
                        </Form.Label>
                        <br />
                        <Form.Control type="password" className="form_enter" name="password" />
                        <Form.Label>
                          <b>Heslo znovu</b>
                        </Form.Label>
                        <br />
                        <Form.Control type="password" className="form_enter" name="passwordAgain" />
                      </Form.Group>
                      {loading ? <div className="spinner-border text-warning" role="status"></div> : null}
                        <br></br>
                      <Button variant="btn btn-warning" type="submit" onClick={handleSave}>
                        Změnit heslo
                      </Button>
                    </Form>
                    <br/>
                      <button className="link" onClick={()=> {(navigate('/login?id='+queryParams.id+'&user='+queryParams.user))}}>
                          Zpět
                      </button>
                      <br/>
                      <br/>
                    {notSame ?  <div className="alert alert-danger" role="alert"><h4 className="alert-heading">{errorMessage}</h4></div> : null}
                    {!userExist ?  <div className="alert alert-danger" role="alert"><h4 className="alert-heading">{errorMessage}</h4></div> : null}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SaveUser;