import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './index.scss';
import App from './App';
import EnterForm from './EnterForm';
import Register from './Register';
import Login from './Login';
import InfoPage from './InfoPage'
import PlayGround from './PlayGround'
import BlogPage from './BlogPage'
import MealList from './MealList'
import Output from './Vystup'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
        <Routes>
              <Route path="/" element={<App/>} exact/>
              <Route path="/infopage" element={<InfoPage/>} />
              <Route path="/playground" element={<PlayGround/>} />
              <Route path="/enterform" element={<EnterForm/>} />
              <Route path="/register" element={<Register/>} />
              <Route path="/login" element={<Login/>} />
              <Route path="/blog" element={<BlogPage/>} />
              <Route path="/meallist" element={<MealList/>} />
              <Route path="/guestsinfo" element={<Output/>} />

       </Routes>  
  </Router>
)
