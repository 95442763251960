import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Styl editoru
import './App.scss'
import './home.scss'
import './editor.scss'
import { Container, Row, Col, Button } from 'react-bootstrap'

import { getCookieValue } from './getCookieValue.js'


const Editor = ({ onSave }) => {
    const apiUri = process.env.REACT_APP_APIURL+'savearticle/'
    const [content, setContent] = useState('');
    const [weddingId, setWeddingId] = useState('')

    const base64Credentials = btoa(process.env.REACT_APP_APIUSERNAME+':'+process.env.REACT_APP_APIPASSWORD);
    const authHeader = 'Basic ' + base64Credentials;

    const userConfig = {
    headers: {
        Authorization: authHeader
    }
    };

    const handleSave = () => {
        onSave(content)
        const result ={
            weddingId: weddingId,
            content: content
        }

            Axios.post(apiUri+weddingId,result, userConfig)
            .then(function (response) {
                
                console.log("Článek uložen",response)

            })
            .catch(function (error) {
                console.log(error);
            })


        setContent('')
    }

    useEffect(() => {

        if(document.cookie.includes("weddingId")){
            setWeddingId(getCookieValue("weddingId"))
        }
      
      }, []);

    return (
        <div className='App'>         
            <Container>
                <Row>
                    <Col>
                        <div className="col">
                          <div className="row">
                            <div className="col-12">
                                <div className="sg-panel sg-panel--paper">
                                    <ReactQuill
                                        value={content}
                                        onChange={setContent}
                                        theme="snow"
                                        placeholder="Zadejte zde obsah článku..."
                                    />
                                    <Button className="button" variant="btn btn-success" type="button" onClick={handleSave}>
                                        Uložit článek
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Editor;