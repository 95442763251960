import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import './App.scss';
import './home.scss';
import { getCookieValue } from './getCookieValue.js';


function HeaderMenu() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Definice komponent mimo HeaderMenu funkci
      const CreateAccountButton = ({ navigate }) => (
          <Button className="button navbar_menu" variant="nav-item nav-link active" type="button" onClick={() => { navigate('/enterform') }}>
            Vytvořit účty
          </Button>
      );

      const AddArticleButton = ({ navigate }) => (
          <Button className="button navbar_menu" variant="nav-item nav-link active" type="button" onClick={() => { navigate('/blog') }}>
            Přidej článek
          </Button>
      );

      const MealListButton = ({ navigate }) => (
          <Button className="button navbar_menu" variant="nav-item nav-link active"  type="button" onClick={() => { navigate('/meallist') }}>
            Vytvoř seznam pokrmů
          </Button>
      );
      const GuestInfo = ({ navigate }) => (
          <Button className="button navbar_menu" variant="nav-item nav-link active" type="button" onClick={() => { navigate('/guestsinfo') }}>
            Informace hostů
          </Button>
      );

      return (
        <Row>
          <Col>
            <nav className="navbar navbar-expand-lg navbar-light">
              <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} style={{border: '3px solid #2b2a29', borderRadius: '15px'}}>
                <div className="navbar-nav">
                  <Button className="button navbar_menu" variant="nav-item nav-link active" type="button" onClick={() => { navigate('/') }}>
                    Formulář
                  </Button>
                  <Button className="button navbar_menu" variant="nav-item nav-link active" type="button" onClick={() => { navigate('/infopage') }}>
                    Informace
                  </Button>
                  <Button className="button navbar_menu" variant="nav-item nav-link active" type="button" onClick={() => { navigate('/playground') }}>
                    PlayGround
                  </Button>
                  {getCookieValue("isAdmin") === 'true' ? <AddArticleButton navigate={navigate} /> : <div></div>}
                  {getCookieValue("isAdmin") === 'true' ? <MealListButton navigate={navigate} /> : <div></div>}
                  {getCookieValue("isAdmin") === 'true' ? <CreateAccountButton navigate={navigate} /> : <div></div>}
                  {getCookieValue("isAdmin") === 'true' ? <GuestInfo navigate={navigate} /> : <div></div>}
                </div>
              </div>
            </nav>
            <br />
          </Col>
        </Row>
      );
    }

export default HeaderMenu;
